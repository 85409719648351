<template>
  <div class="page-pay">
    <div class="paycard">
      <div class="payText">支付金额(元)</div>
      <div class="payNumber">
        {{ $filters.formatMoney(lastInfo.repayAmount, 2) }}
      </div>
    </div>
    <div class="payLine" @click="goback">
      <div class="lineRight line-rigt-text" v-if="bankSrc == ''">
        请选择银行卡
      </div>
      <div class="lineRight" v-else>
        <img :src="bankSrc" alt="" class="bankIcon"/>
        <div class="bankText">{{ bankName }} ({{ bankIn }})</div>
      </div>
      <div class="lineLeft"></div>
    </div>
    <div class="payfrom">
      <div class="payMobile">
        <div class="mobileText">手机号</div>
        <input
          class="moblieNum"
          readonly
          :value="lastInfo.mobile"
          placeholder="请输入手机号"
        />
      </div>
      <div class="payMobile payoutline">
        <div class="mobileText">验证码</div>
        <input
          class="moblieNum"
          type="tel"
          oninput="if(value.length>6)value=value.slice(0,6)"
          maxlength="6"
          v-model="verifyCode"
          @change="changeInput($event)"
          placeholder="填写验证码"
        />
        <div class="mobileCode" @click="GetVerifyCode" v-show="canSendCode">
          {{ verifyTxt }}
        </div>
        <div
          class="mobileCode-min"
          @click="GetVerifyCode"
          v-show="!canSendCode"
        >
          {{ count }}秒
        </div>
      </div>
    </div>
    <!-- 底部 支付协议以及按钮-->
    <div class="payprotocol">
      <div
        :class="checked == true ? 'pro-icon' : 'proIcon-active'"
        @click="gochencked"
      ></div>
      <span class="proText"
            @click="JumpLink('https://res.feiyiyc.com/html/payAgreement.html')">同意《快捷支付还款提醒》</span>
    </div>
    <div class="footer">
      <div class="btn" @click="paySub">去支付</div>
    </div>
    <!-- 添加银行卡 -->
    <Modal
      :show="showback"
      @cancel="cancel"
      :showCancel="true"
      class="bankpages"
      :title="'选择收款账户'"
    >
      <div class="modalMain">
        <div class="modalContain">
          <div
            class="modalLine"
            v-for="item in bankList"
            :key="item.bankAccount"
            @click="checkBank(item)"
          >
            <img :src="item.logo" alt="" class="lineIcon"/>
            <div class="lineText">
              <div>{{ item.openBankName }}（{{ item.bankAccount4 }}）</div>
              <div
                :class="[
                  chenckStatus == item.bankAccount4 ? 'checkedIcon' : ''
                ]"
              ></div>
            </div>
          </div>

          <div class="modalLine" @click="addBank">
            <div class="lineBank"></div>
            <div class="lineText">添加新的银行卡</div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </Modal>
  </div>
</template>
<style scoped lang="less" src="./index.less"></style>
<script>
import Modal from '@/components/Modal/Modal.vue'
import { reactive, toRefs, onMounted } from 'vue'
import { Toast } from 'vant'
import API from '../../server/api'
import { Jump } from '@/utils/routh'
import { useRouter } from 'vue-router'

export default {
  name: 'Index',
  components: { Modal },
  setup () {
    const Router = useRouter()
    const state = reactive({
      userId: '',
      showback: false, // 银行卡
      periodList: [],
      // chenckStatus:false,//选中的状态
      payAmt: 0, // 还款金额
      bankList: [], // 银行卡
      chenckStatus: null,
      bankAccount: '', // 银行卡号
      bankSrc: '',
      bankName: '',
      bankIn: '',
      verifyCode: '',
      countdown: 60,
      verifyTxt: '点击获取验证码',
      canSendCode: true,
      clientNo: '',
      productName: '',
      clientCell: '',
      periodNos: [],
      timer: null, // 倒计时
      count: '', // 倒计时
      checked: true, // 协议选中
      payType: '', // 还款类型
      lastInfo: {}
    })

    // 获取银行卡列表
    const init = async () => {
      try {
        const res = await API.seecardList({
          userId: state.userId,
          loanNo: state.loanNo
        })
        if (res.success) state.bankList = res.result
      } catch (err) {
        console.log(err.message)
      }
    }
    const checkBank = item => {
      state.chenckStatus = item.bankAccount4
      state.bankSrc = item.logo
      state.bankName = item.openBankName
      state.bankIn = item.bankAccount4
      state.bankAccount = item.bankAccount
      state.showback = false
    }
    // 获取验证码
    const GetVerifyCode = async () => {
      if (!state.bankAccount) {
        Toast('请选择银行卡')
        return false
      }
      try {
        const res = await API.backSms({
          isSettle: false,
          loanNo: state.loanNo,
          bankCard: state.bankAccount // 银行卡
        })
        if (res.success) {
          countdowns()
        } else {
          Toast('获取验证码失败')
          return false
        }
      } catch (err) {
        Toast(err.message)
        console.log(err.message)
      }
    }
    // 验证码倒计时
    const countdowns = () => {
      const TIME_COUNT = 60
      if (!state.timer) {
        state.count = TIME_COUNT
        state.canSendCode = false
        state.timer = setInterval(() => {
          if (state.count > 0 && state.count <= TIME_COUNT) {
            state.count--
          } else {
            state.canSendCode = true
            clearInterval(state.timer)
            state.timer = null
          }
        }, 1000)
      }
    }
    // 获取验证码的input输入值
    const changeInput = e => {
      if (!e.target.value || e.target.value.length > 6) {
        Toast('请输入正确的验证码')
        return false
      }
      state.verifyCode = e.target.value
    }

    // 还款提交
    const paySub = async () => {
      if (!state.bankAccount) {
        Toast('请选择银行卡')
        return false
      }
      if (!state.verifyCode) {
        Toast('请先获取短信验证码')
        return false
      }
      if (!state.checked) {
        Toast('请同意支付还款协议')
        return false
      }
      try {
        const res = await API.backMoney({
          loanNo: state.loanNo,
          isSettle: false,
          bankCard: state.bankAccount,
          periods: state.lastInfo.list, // 期数列表
          verifyCode: state.verifyCode // 验证码
        })
        if (res.success) {
          Toast.success('提交成功')
          setTimeout(function () {
            Router.push('/payResult')
          }, 2000)
        }
      } catch (err) {
        console.log(err.message)
      }
    }
    // 协议选中
    const gochencked = () => {
      state.checked = !state.checked
    }

    // 银行卡弹框
    const goback = () => {
      state.showback = true
      init()
    }
    const cancel = show => {
      state.showback = show
      state.chenckStatus = false
    }
    const confirm = show => {
      state.showback = show
    }
    // 添加新的银行卡
    const addBank = () => {
      state.showback = false
      Jump('/addAccount')
    }

    const JumpLink = (url) => {
      Jump(url)
    }

    onMounted(() => {
      state.userId = localStorage.getItem('userId')
      state.loanNo = localStorage.getItem('loanNos')
      state.periodList = localStorage.getItem('periodList')
      const lastInfo = localStorage.getItem('backResult')
      state.lastInfo = JSON.parse(lastInfo)

      // 初始化
      init()
    })

    return {
      ...toRefs(state),
      goback,
      checkBank,
      cancel,
      confirm,
      GetVerifyCode,
      countdowns,
      paySub,
      addBank,
      changeInput,
      gochencked,
      JumpLink
    }
  }
}
</script>
